body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  @media (max-width: @screen-sm-max)
  {
  overflow-x: hidden;
  }
}

a {
  color: @link-color;
  &:hover {
    text-decoration: none;
  }
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
}

header.navbar {
  background-color: @header-bg;
  border: none;
  @media (max-width: @screen-sm-max) {
    margin-bottom: 5px;
  }


  .logo-trib {
    position:absolute;
    top: 10px;
    left: -15px;
  }

  .container-fluid:first-child {
    .navbar-brand {
      margin-left: -35px;
      padding-left: 0px;
    }
    .navbar-right {
      margin-top: 5px;
      @media (max-width: @screen-xs-max) {
        margin-top: -48px !important;
        padding-top: 0 !important;
      }
    }
  }

  .container-fluid:last-child {
    border-bottom: 1px solid @header-border;
    margin-top: 25px;

    p {
      margin: 0;
    }

    .navbar-main {
      margin-top: 1px;
      li {
        a {
          background-color: @header-navbar-element-bg;
          border: 1px solid @header-navbar-element-border;
          border-bottom: none;
          color: @header-navbar-element-color;
          margin-right: 1px;
          padding: 3px 5px;
          text-align: center;
          -webkit-transition: background 0.15s ease-in;
          -moz-transition: background 0.15s ease-in;
          -ms-transition: background 0.15s ease-in;
          -o-transition: background 0.15s ease-in;
          transition: background 0.15s ease-in;

          &:hover {
            background-color: @header-navbar-element-hover-bg;
            color: @header-navbar-element-hover-color;
            -webkit-transition: background 0.15s ease-in;
            -moz-transition: background 0.15s ease-in;
            -ms-transition: background 0.15s ease-in;
            -o-transition: background 0.15s ease-in;
            transition: background 0.15s ease-in;
          }
        }

        &.active {
          a {
            background-color: @header-navbar-element-active-bg;
            color: @header-navbar-element-active-color;
          }
        }
      }
    }

    .navbar-right {
      margin-bottom: 0px;
      margin-top: 0px;

      .form-group {
        margin-bottom: 0px;
      }

      .form-control {
        background-color: @header-navbar-form-bg;
        border: none;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
        height: 28px;
      }

      .btn {
        background-color: @header-navbar-form-btn-bg;
        border: none;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
        color: @header-navbar-form-btn-color;
        padding: 4px 12px;
      }
    }
  }

  .dropdown {
    padding-right: 15px;
    button.navbar-toggle {
      span#menu_simul_icon {
        float: left;
        padding-top: 3px;
        padding-right: 5px;
      }
      span:last-child {
        float: left;
      }
    }
  }

  .navbar-form {
    &.navbar-form-custom {
      margin-left: 0px;
      margin-right: 0px;
      padding: 0px;
      border-top: none;
      border-bottom: none;
    }
  }

  .header-navbar-collapse-menu {
    li {
      border-bottom: 1px solid @header-navbar-form-btn-color;

      a, a:active{
        background-color: @blu !important;
        color: @header-navbar-element-color !important;
        box-shadow: none !important;
      }
    }
  }
}

.breadcrumb {
  background-color: transparent;
  border-bottom: 1px solid @breadcrumb-border-color;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  margin-bottom: 15px;
  padding-left: 0px;

  li {
    & + li:before {
      content: '>>';
    }
  }
}

.container-main {
  margin-bottom: @container-main-margin-bottom-normal;
  margin-top: 15px;
  min-height: 500px;
  @media (max-width: @screen-xs-max) {
    margin-bottom: @container-main-margin-bottom-xs;
  }

  h1.page-title {
    border-bottom: 1px solid @page-title-border-color;
    font-weight: 300;
    margin-bottom: 30px;
    margin-top: 0;
    padding-bottom: 5px;
  }

  section {
    div.row {
      aside.col-xs-12 {
        @media (max-width: @screen-xs-max) {
          margin-top: @panel-margin-bottom-xs;
        }
      }
    }
  }
}

.padding-5{
  padding: 0% 5%;
}

.sidebar-left {
  @media (max-width: @screen-xs-max) {
    margin-top: @container-main-margin-bottom-xs;
  }
}

footer {
  background-color: @footer-bg;
  border-top: 1px solid @footer-border-color;
  padding-bottom: 30px;
  padding-top: 30px;

  nav {
    &.navbar {
      background-color: @footer-navbar-bg;
      border: none;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      color: @footer-navbar-color;
      height: 30px;
      margin-top: 45px;
      min-height: 30px;

      @media (max-width: @screen-xs-max)
      {
        height: inherit;
        min-height: inherit;
      }

      ul.list-inline {
        display: inline-block;
        float: none;
        height: 30px;

        li {
          background-color: transparent;
          border-right: 1px solid @footer-navbar-separator-color;
          padding: 5px 15px;
          -webkit-transition: background 0.15s ease-in;
          -moz-transition: background 0.15s ease-in;
          -ms-transition: background 0.15s ease-in;
          -o-transition: background 0.15s ease-in;
          transition: background 0.15s ease-in;

          &:last-child {
            border-right: none;
          }

          &:hover {
            background-color: @footer-navbar-element-hover-bg;
            -webkit-transition: background 0.15s ease-in;
            -moz-transition: background 0.15s ease-in;
            -ms-transition: background 0.15s ease-in;
            -o-transition: background 0.15s ease-in;
            transition: background 0.15s ease-in;
          }

          a {
            color: @footer-navbar-element-link-color;

            &:hover {
              color: @footer-navbar-element-link-hover-color;
            }
          }
        }
      }
    }

    @media (max-width: @screen-xs-min)
    {
      .footer-menu {
        padding-top: 0.5rem;
        line-height: 2.5rem;
        margin-bottom: 20px;
        background-color: @footer-navbar-bg;
        a {
          color: @footer-link-menu-color;
        }

        li {
          color: @footer-link-menu-color;

          @media (max-width: @screen-xs-max) {
            &:after {
              content: '|';
              margin-left: 10px;
            }

            &:last-child {
              &:after
              {
                content: none;
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }

  p {
    color: @footer-p-color;
  }
  
  a[href^="mailto"], a[href*="mailto"] {
    color: @footer-link-email-color;
  }

  hr {
    height: 2px;
    background-color:#ddd;
    width: 75%;
  }

  .footer-banner {
    margin-bottom: 20px;
  }
}

.footer-slim{
  background-color: #ffffff;
  border-top: 2px solid @blu;
  padding: 0px;

  nav {
    &.navbar {
      background-color: transparent;
      color: @blu;
      margin: 0px;
      height: 20px;
      min-height: 20px;

      ul.list-inline {
        height: 20px;

        li {
          background-color: transparent;
          border-right: 1px solid @blu;
          padding: 0px 15px;

          &:hover {
            background-color: transparent;
            text-decoration: underline;
          }

          a {
            color: @blu;
            font-weight: bold;

            &:hover {
              color: @blu;
            }
          }
        }
      }
    }
  }

  .navbar-row{
    margin: 10px 0px;
  }

  p {
    color: #525252;
  }

  a[href^="mailto"], a[href*="mailto"] {
    font-weight: bold;
    color: @blu;
  }

  hr {
    height: 1px;
    margin: 0px 0px 10px 0px;
    background-color:#ddd;
    width: 100%;
  }
}

#decrease-chars,
#normal-chars,
#increase-chars {
  vertical-align: middle;
}

#modify-chars-container {
  margin: 4px 0px 4px 0px;
}

.evidenza-red{
  background-color:#BF0016 !important;
  color:#fff !important;
}

.color-red{
  color: @rosso;
  font-weight: bold;
}


.banner_img_ministero {
  border: 4px solid #f1f1f1 !important;
  padding-right: 0 !important;
  background-position: center left;
}